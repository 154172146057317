import { useEffect, useState } from 'react';
import { getStoredQueryParams } from 'utils/analyticsHelpers';

/**
 * @summary use this hook to append query params to a link
 * @param {String} linkUrl - any url
 * @param {Object} queryParams - more params to send
 */
export default function useAppendQueryParams(linkUrl, queryParams = {}) {
  const [link, setLink] = useState(linkUrl);

  const queryParamsKey = Object.values(queryParams).join('_');

  useEffect(() => {
    let newLink = '';

    // Step 1: get stored params
    const persistedQueryParams = getStoredQueryParams();

    const moreParams = persistedQueryParams.requestArray
      .map((param) => {
        return `${param.name}=${param.value}`;
      })
      .join('&');

    // Step 2: append stored params to link
    if (persistedQueryParams.requestArray.length && linkUrl) {
      const linkHasParams = linkUrl.includes('?');
      newLink = `${linkUrl}${linkHasParams ? '&' : '?'}${moreParams}`;
    } else {
      newLink = linkUrl;
    }

    const additionalParams = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');

    // Step 3: append additional params to link
    if (additionalParams.length && linkUrl) {
      newLink = newLink.includes('?')
        ? `${newLink}&${additionalParams}`
        : `${newLink}?${additionalParams}`;
    }

    setLink(newLink);
  }, [linkUrl, queryParamsKey]);

  return link;
}
